/* eslint-disable no-confusing-arrow */
import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { FormHelperText } from '@mui/material';
import moment from 'moment';
import { convertToArborDate } from 'models/time/arbor-date';
import { EXPIRATION_DATE_FORMAT, BIN_LENGTH_VALIDATION } from 'constants/index';
import { styles } from './validation-styles';

const INVALID_FORM = 'Invalid form';

const maxNumberCache = {};
const minNumberCache = {};

const Validation = ({ classes, touched, error }) => (
  <FormHelperText className={classes.errorMessage}>{touched ? error : ''}</FormHelperText>
);

const Validation2 = ({ classes, error }) => (
  <FormHelperText className={classes.errorMessage}>{error || ''}</FormHelperText>
);

export const required = value => {
  if (Array.isArray(value)) {
    return value.length === 0 ? 'Required' : undefined;
  }
  return value || value === 0 ? undefined : 'Required';
};

export const optionSelected = value => {
  return !value || (value && value.value < 0) ? (
    <div style={{ color: 'red' }}>Select an option from dropdown</div>
  ) : undefined;
};

export const addressRequired =
  (address = []) =>
  () =>
    address.length === 0 ? 'Address is required' : undefined;

export const requiredNotWhitespace = value => {
  if (Array.isArray(value)) {
    return value.length === 0 ? 'Required' : undefined;
  }
  if (value && typeof value === 'string') {
    value = value.trim();
  }
  return value || value === 0 ? undefined : 'Required';
};

export const defined = value => (value !== null && value !== undefined ? undefined : 'Required');

export const definedBoolean = value => !!(value !== null && value !== undefined);

export const validateNumber = value =>
  value && Number.isNaN(Number(value)) ? 'Must be a number' : undefined;

export const validateNumeric = value =>
  value && !String(value).match(/^-?[0-9]+$/) ? 'Must be all numbers' : undefined;

export const validateAlphaNumeric = value =>
  value && !String(value).match(/^[a-zA-Z0-9]+$/) ? 'Must be alphanumeric' : undefined;

export const alwaysValid = () => undefined;

export const maxNumber = max => {
  if (maxNumberCache[max]) {
    return maxNumberCache[max];
  }

  maxNumberCache[max] = value => {
    if (value && Number(value) > max) {
      return `Must be less than ${max}`;
    }

    return undefined;
  };
  return maxNumberCache[max];
};

export const minNumber = min => {
  if (minNumberCache[min]) {
    return minNumberCache[min];
  }

  minNumberCache[min] = value => {
    if (value && Number(value) < min) {
      return `Must be at least ${min}`;
    }

    return undefined;
  };
  return minNumberCache[min];
};

export const maxLength = max => value =>
  value && value.length > max ? `Must be less than ${max}` : undefined;

export const minLength = min => value =>
  value && value.length < min ? `Must be at least ${min}` : undefined;

// eslint-disable-next-line arrow-body-style
export const length = (exactLength, errorMsg) => value => {
  return value && value.length !== exactLength ? errorMsg : undefined;
};

export const length6 = length(6, BIN_LENGTH_VALIDATION);

export const maxLength8 = maxLength(8);

export const maxLength20 = maxLength(20);

export const maxLengthSMS = maxLength(918);

export const validateDollarValue = value =>
  value && Number.isNaN(Number(value.toString().replace(/,/g, '').replace(/\$/g, '').trim()))
    ? 'Must be a dollar amount'
    : undefined;

export const validateLessThanFiveDigitsValue = value => {
  if (!value) return undefined;
  const currencyValue = Number(value?.toString().replace(/,/g, '').replace(/\$/g, '').trim());
  return value && !Number.isNaN(currencyValue) && Math.abs(currencyValue).toString().length > 5
    ? 'Value must be less than 5 digits'
    : undefined;
};

export const validateYear = value =>
  value && !isNaN(Number(value)) && value.toString().length !== 4 //eslint-disable-line
    ? 'Invalid Year Format'
    : undefined;

export const validateUniqueIncomeYear = years => value =>
  years.indexOf(Number(value)) === -1 ? undefined : 'Duplicate Income Year';

// Primary Insurance TypeId is 1
export const validateSinglePrimaryInsurance = (hasPrimary, endTime) => value =>
  hasPrimary && value === 1 && (!endTime || (endTime && Object.keys(endTime).length < 2))
    ? 'Multiple Active Primary Insurances'
    : undefined;

export const validatePhone = value =>
  value &&
  !/^(0|[1-9][0-9]{9})$/i.test(
    value
      .replace(/"/g, '')
      .replace(/-/g, '')
      .replace(/'/g, '')
      .replace(/\(|\)/g, '')
      .replace(/\s/g, ''),
  )
    ? 'Invalid phone number'
    : undefined;

export const validateEmail = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(value)
    ? 'Invalid email address'
    : undefined;

export const validateDateIsBefore = maxDate => value => {
  const maxMoment = moment(maxDate);
  const selectedMoment = moment(value);

  return selectedMoment.isBefore(maxMoment, 'day')
    ? undefined
    : `Must be before ${maxMoment.format('MM/DD/YYYY')}`;
};

export const validateDateIsNotInTheFuture = value => {
  const today = moment();
  const selectedMoment = moment(value);
  return selectedMoment.isSameOrBefore(today, 'day') ? undefined : 'Must not be in the future';
};

export const validateDateIsTodayOrAfter = value => {
  const today = moment();
  const selectedMoment = moment(value);

  return selectedMoment.isSameOrAfter(today, 'day') ? undefined : 'Must not be in the past';
};

export const validateLast4ssn = value =>
  value && !/^[0-9]{4}$/i.test(value) ? 'Invalid ssn' : undefined;

export const validateDate = values =>
  !values || moment(values, 'MM/DD/YYYY', true).isValid() ? '' : 'INVALID FORMAT';

export const validateDateTime = values =>
  !values || moment(values, 'MM/DD/YYYY hh:mm A', true).isValid() ? '' : 'INVALID FORMAT';

export const validateSubForm = value => {
  try {
    return !value || value.valid ? undefined : INVALID_FORM;
  } catch (e) {
    return INVALID_FORM;
  }
};

export const validateSubForms = value => {
  try {
    return !value || value.every(v => !!v.valid) ? undefined : INVALID_FORM;
  } catch (e) {
    return INVALID_FORM;
  }
};

export const therapyNBDWithinDaysSupply = (needsByDate, daysSupply) => {
  try {
    if (!needsByDate || !daysSupply) {
      return true;
    }

    const arborDateNBD = convertToArborDate(needsByDate).getUtcDate();
    const arborDateDaysSupply = convertToArborDate(moment().add(daysSupply, 'days')).getUtcDate();
    const arborDateNow = convertToArborDate(moment()).getUtcDate();

    const momentNBD = moment(arborDateNBD);
    const momentDaysSupply = moment(arborDateDaysSupply);
    const momentNow = moment(arborDateNow);
    if (momentNBD.isSameOrBefore(momentDaysSupply) && momentNBD.isSameOrAfter(momentNow)) {
      return true;
    }
    return 'Needsby date is after end of days supply';
  } catch (e) {
    return INVALID_FORM;
  }
};

export const mustBeYes10 = value => (value === '1' ? undefined : 'Correct incorrect field');
export const validateContact = value => {
  if (!value) {
    return undefined;
  }

  if (!value.visible) {
    return undefined;
  }

  if (value.doesnt_have_emergency_contact || value.declined_to_provide_emergency_contact) {
    return undefined;
  }

  if (value.is_responsible) {
    if (value.first_name && value.last_name && value.relationship) {
      return undefined;
    }
  } else if (value.first_name && value.relationship) {
    return undefined;
  }
  return 'Please provide required contact information';
};

export const cardExpirationValidation = values => {
  if (!values) {
    return '';
  }

  if (!moment(values, EXPIRATION_DATE_FORMAT, true).isValid()) {
    return 'The format must be MM/YYYY';
  }

  if (moment(values, EXPIRATION_DATE_FORMAT).format('YYYY-MM') < moment().format('YYYY-MM')) {
    return "Credit card can't be expired";
  }
};

export const rsGraduationTypeMatchValidation = values => {
  return 'Enrollment/Graduation Values do not match';
};

export const noDrugNameValidation = drugName => {
  const validationFn = input => {
    if (!input || !drugName) return undefined;

    const content = typeof input === 'string' ? input : input.value;
    if (!content) return undefined;

    if (drugName && content.toLowerCase().indexOf(drugName.toLowerCase()) !== -1) {
      return 'The drug name should not be provided in notes';
    }
    return undefined;
  };

  return validationFn;
};

export default withStyles(styles, { withTheme: true })(Validation);
export const ImmediateValidation = withStyles(styles, { withTheme: true })(Validation2);
