import React, { useEffect, useState, useCallback, useRef } from 'react';
import Mentions from 'rc-mentions';
import lodash from 'lodash';
import Validation from 'components/form/validation/validation';
import { useTypedSelector } from '../../hooks/use-typed-selector';

const INPUT_DEBOUNCE_MS = 500;

const NoteArea = props => {
  const {
    input,
    data,
    disabled,
    maxLength,
    defaultPlaceholder,
    id,
    'data-qa-id': dataQaId,
    meta,
    autoFocus,
  } = props;

  const [val, setVal] = useState(input.value);
  const noteBar = useTypedSelector(state => state.noteBar);
  const debouncedFnRef = useRef();

  useEffect(() => {
    debouncedFnRef.current = lodash.debounce(value => {
      input.onChange(value);
    }, props.debounceOverride || INPUT_DEBOUNCE_MS);

    return () => {
      debouncedFnRef.current?.cancel();
    };
  }, [input, props.debounceOverride]);

  const handleChange = useCallback(e => {
    setVal(e);
    debouncedFnRef.current?.(e);
  }, []);

  useEffect(() => {
    if (input.value !== val) {
      setVal(input.value);
    }
  }, [input.value]);

  const ref = useRef();
  useEffect(() => {
    if (!autoFocus) {
      return;
    }

    setTimeout(() => {
      ref?.current?.focus();
    }, 1000);
  }, [noteBar.display]);

  let options = [];
  if (Array.isArray(data)) {
    options = data.map(d => ({
      value: d.username,
      label: d.display_name,
    }));
  }

  return (
    <>
      <Mentions
        onFocus={() => {
          input.onFocus();
          props?.inputProps?.onFocus?.();
        }}
        onBlur={() => {
          input.onBlur();
          props?.inputProps?.onBlur?.();
        }}
        value={val}
        rows={3}
        ref={ref}
        maxLength={maxLength}
        placeholder={defaultPlaceholder || 'Add a Note'}
        disabled={disabled}
        id={id}
        data-qa-id={dataQaId}
        onChange={handleChange}
        options={options}
      />
      <Validation touched={meta.touched} error={meta.error} warning={meta.warning} />
    </>
  );
};

export default NoteArea;
